<template>
  <router-view />
</template>

<script>
export default {
  name: 'ClientResource',
  created () {
  },
  methods: {
    
  },
}
</script>